import { wordWrap } from "@/utils";
import settings from "@/settings"
let name = settings.name
const company = settings.company
if (name === 'VOC') name = 'voc'
export const privacy = wordWrap(`${name}安全服务隐私权政策

为切实保护用户隐私权，优化用户体验，${name}智能家居（“${name}”或“我们”）根据现行法规及政策，制定本${name}智能家居隐私权政策。${name}了解个人信息对客户的重要性，我们力求明确说明我们获取、管理及保护用户个人信息的政策及措施。

本隐私权政策将帮助您了解以下内容：

一、我们会收集哪些信息（无论其是否为个人信息）

二、Cookie和网络Beacon等同类技术的使用

三、我们如何使用信息

四、我们如何共享信息

五、我们如何转让信息

六、我们如何公开披露信息

七、一般储存期限

八、我们如何确保您的信息安全

九、您能做什么

十、我们如何处理未成年人的个人信息

十一、您的个人信息的存储地点

十二、本隐私权政策不适用的范围

十三、本隐私权政策如何更新

十四、联系我们

本隐私权政策适用于引用或链接本隐私权政策的与${name}智能家居有关的${name}设备、网站或应用程序（统称为我们的“服务”），包括但不限于${name}官网（域名为 ${name}.so）、${name}商城（域名为 ${name}.so）、${name} App、${name}物联网家电设备等设备、网站及客户端。虽然本隐私权政策适用于所有这些服务，但我们也针对特定服务制定了某些明确的隐私权补充条款，其中包含有关我们的信息处理做法的其他信息。使用相关的服务时，您必须遵守这些补充条款。当我们进行其他数据收集处理活动时会另外取得您的同意。

${name}深知个人信息对您的重要性，并会尽全力保护您的个人信息安全可靠。我们致力于维持您对我们的信任，恪守以下原则保护您的个人信息：权责一致原则、目的明确原则、选择同意原则、最少够用原则、确保安全原则、主体参与原则、公开透明原则等。同时，${name}承诺，我们将按业界成熟的安全标准，采取相应的安全保护措施来保护您的个人信息。

请务必仔细阅读本隐私权政策，特别是免除/限制责任的条款（以粗体下划线标识）,因为只要您使用我们的服务，就表示您同意隐私权政策及补充条文中所描述的做法。如果您不同意本隐私权政策中所描述的做法，请不要使用我们的服务。

 

一、     我们会收集哪些信息（无论其是否为个人信息）

为了向您提供我们的服务，我们会要求您提供向您提供服务所必需的个人信息。如果您不提供个人信息，我们可能无法向您提供我们的产品或服务。“个人信息”指以电子或者其他方式记录的能够单独或者与其他信息结合识别特定自然人身份或者反映特定自然人活动情况的各种信息。

您直接提供的信息：某些服务会要求您直接向我们提供信息，如会员注册、购买、送货及安装服务。

(1)      会员注册：当您注册${name}会员及您在使用${name}提供的相关服务时填写及/或提交的信息，包括您的姓名、性别、出生年月日、身份证号码、电话号码、电子邮箱、地址、头像、昵称等相关附加信息（如您所在的省份和城市、邮政编码等）。

(2)      购买、送货及安装等服务：我们可能会询问您的姓名、联系信息、配送地址和账单地址以及信用卡信息，以便处理您的订单。

当您使用某些服务时，您有可能还需要向我们提供其他信息，如家庭人数、食材偏好等。若涉及个人敏感信息，例如提供声纹、指纹、面部识别特征、健康生理信息等信息，在收集前我们会有专门的提示，并在可行的情况下提供替代的选择（例如若您拒绝使用面部识别功能识别您的身份，我们会在可行的情况下，允许您选择使用密码验证、证件验证等其他方式）。

个人敏感信息特别提醒：某些特殊的个人信息可能被认为是个人敏感信息，例如您的个人身份信息、账户信息、行为信息、生物识别信息和网络身份标识信息、联系方式。在提供此类个人敏感信息前，您需要谨慎考虑，确保准确。海尔在此征得您的同意，您同意这些个人敏感信息将按照本隐私权政策所阐明的目的和方式来进行处理。如果您不提供这些信息或提供的信息有误，可能导致我们无法提供服务或影响我们提供服务的准确性。

来自设备的信息：我们还可能通过您设备中的软件以及其他方式来收集您使用我们的服务的相关信息，如设备信息、日志信息、位置信息等。

(1)      设备信息：您下载或使用${name}客户端软件，或访问网页使用${name}服务时，${name}可能会读取与您移动设备相关的信息，包括但不限于硬件型号、IMEI号码和其他唯一设备标识符、MAC地址、IP地址、操作系统版本以及用于访问服务的设备的设置；读取您使用的海尔物联网电器的使用情况，包括但不限于设备型号、设备运行状态、设备使用频次、某些设备内置摄像头可能会拍摄设备的使用情况等。

(2)      日志信息：在您使用${name}服务，或访问${name}网站时，${name}自动接收并记录的您的浏览器和计算机上的信息，包括但不限于（1）您的IP地址、浏览器的类型、使用的语言、访问日期和时间、软硬件特征信息及您需求的网页记录等数据；（2）您物联网设备的日志信息。

(3)      位置信息（仅适用于特定服务/功能）：您下载或使用${name}客户端软件或访问网页，以及使用某些${name}服务时，${name}可能会读取与您位置相关的各类信息，例如设备的GPS信号或附近的WiFi接入点和手机信号发射塔的相关信息、国家代码、城市代码、移动网络代码、小区标识、经纬度信息和语言设置，这些信息可能会在您使用某些服务时传送给我们。

(4)      使用信息：您使用某些${name}服务时，${name}客户端软件连接的${name}设备将上传的测量数据或其他使用信息，例如：体脂称、肌肤仪、健康小管家、肌肤仪、血糖仪、尿检仪等设备测量的健康数据，食材存储信息或照片。

第三方来源信息：我们可能会从第三方合作伙伴获得您的信息，例如我们的经销商、您选择连接的第三方社交网络服务等。

我们收集的其它信息：除上述信息外，我们也可能收集其他信息，例如，您参与问卷调查时向我们发送的问卷答复信息、参加用户体验计划以及您与我们以及您与海尔合作伙伴之间互动时我们收集的相关信息。我们会在收集时向您说明具体收集的内容和具体的收集方式，征得您的同意。当我们要将基于特定目的收集而来的信息用于其他目的时，会事先征求您的同意。

您可以选择不提供某些类型的信息（例如，位置信息、通过${name}设备测量的健康数据），但这样可能会影响您使用某些服务的能力。例如，如有必要，您可以通过APP、设备本身（适用于部分设备）或恢复出厂设置来单独取消每个联网设备与互联网之间的连接的方式，不向${name}服务器传输${name}物联网电器的使用信息和日志信息。但这样可能会导致（1）您将无法使用APP的某些功能；和/或（2）您只能通过家电本身来操作家电，而无法再通过APP执行操作。再如，您可以通过在手机上关闭APP的位置信息读取权限的方式不向${name}服务器传输您的位置信息，或者某些情况下您可以选择本地存储，不予上传。但这会导致您无法使用APP的某些功能。

 

二、    Cookie和网络Beacon等同类技术的使用

Cookie技术：您访问${name}相关网站或使用海尔提供的服务时，我们可能会通过小型数据文件识别您的身份，这么做是为了帮您省去重复输入注册信息的步骤，或者帮助判断您的账户安全。这些数据文件可能是Cookie、Flash Cookie或您的浏览器或关联应用程序提供的其他本地存储（统称“Cookie”）。

Cookie是计算机、电视、手机或其他设备上存储信息的小文件。在您的设备上放置Cookie的实体可以通过Cookie在不同网站、服务、设备和/或浏览会话中识别您。Cookie具有许多用途。例如：Cookie可以记住您的登录凭据，这样您就不必在每次登录服务时都输入这些凭据。Cookie帮助我们和第三方了解我们的哪部分服务最受欢迎，因为Cookie有助于我们查看访问者访问的是哪些页面和功能，以及在这些页面上花费多长时间。了解此类信息后，我们就能更有效地改善服务并为您提供更好的体验。Cookie帮助我们和第三方了解您观看了哪些广告，这样您就不会在每次访问服务时都收到相同的广告。Cookie通过收集您使用我们的服务以及其他网站和应用程序的信息，帮助我们和第三方为您提供相关的内容和广告。如果您使用Web浏览器访问服务，可以将浏览器配置为接受所有Cookie、拒绝所有Cookie或发送Cookie时通知您。每种浏览器都是不同的，因此请查看浏览器的“帮助”菜单以了解如何更改您的Cookie首选项。您的设备的操作系统可能包含其他Cookie控件。

请您理解，我们的某些服务只能通过使用“Cookie”才可得到实现。如果您的浏览器或浏览器附加服务允许，您可以根据自己的偏好修改对Cookie的接受程度或者拒绝${name}的Cookie，您可以清除计算机上保存的所有Cookie，大部分网络浏览器都设有阻止Cookie的功能，修改或删除Cookie的方法，请参见您的浏览器的使用帮助。但这一举动在某些情况下可能会影响您安全访问${name}相关网站和使用${name}提供的服务。

网站信标和像素标签

除Cookie外，我们还会在网站上使用网站信标和像素标签等其他同类技术。如网页上常会包含一些电子图像（称为“单像素”GIF文件或“网络beacon”），使用网络beacon可以帮助网站计算浏览网页的用户或访问某些cookie，我们会通过网络beacon收集您浏览网页活动的信息，例如您访问的页面地址、您先前访问的援引页面的位址、您停留在页面的时间、您的浏览环境以及显示设定等。又如，我们向您发送的电子邮件可能含有链接至我们网站内容的点击URL。如果您点击该链接，我们则会跟踪此次点击，以帮助我们了解您的产品和服务偏好并改善客户服务。网站信标通常是一种嵌入到网站或电子邮件中的透明图像。借助于电子邮件中的像素标签，我们能够获知电子邮件是否被打开。如果您不希望自己的活动以这种方式被追踪，则可以随时从我们的寄信名单中退订。

移动分析软件

在某些APP中，我们使用移动分析软件，例如友盟数据统计SDK、${name}行为统计SDK，以更好地了解我们的APP在您的手机中的功能。此款软件可能记录以下信息，如您使用该应用程序的频率、该应用程序内发生的事件、累计使用情况、性能数据及应用程序崩溃发生的位置。我们不会将存储于分析软件内的信息关联到您在APP中提交的任何个人信息。


三、    我们如何使用信息

收集个人信息的目的在于向您提供产品和/或服务，并且保证我们遵守适用法律。您特此同意我们出于本隐私权政策规定的目的处理个人信息，并向我们、第三方服务供应商披露个人信息。我们可能将所收集的个人信息用于以下用途和目的：

创建您的${name}账号。将通过网站或移动设备创建海尔账号时收集的个人信息用于建立用户的个人${name}账户和资料页。

处理您的订单。与电子商务订单相关的信息可用于处理订购单和相关的售后服务，包括客户支持和重新发货。此外，订单号将用于交叉检查送货合作伙伴的订单和包裹的实际交付。收件人信息，包括姓名、地址、电话号码和邮政编码将用于送货。电子邮件地址用于向用户发送包裹跟踪信息。购买物品的清单用于打印发票，并允许客户确定包裹中的物品。

使您能够激活和控制、使用设备。您控制您的部分${name}设备需要使用APP登录账户，并正常完成设备的配网、绑定流程。完成这些流程后，您即可通过APP对设备进行远程控制或局域网内控制。部分海尔设备需要激活后方可使用。激活过程需要使用相关的设备信息，包括：MAC地址、机器编码、TYPEID、WIFI密码等。若激活失败，则我们可能读取相关的设备信息和日志信息用于评估激活失败的原因。同时，您控制您的联网设备可能需要使用用户名/密码等信息。

使您能够寻找您的设备。${name}在某些服务中可能提供了“查找设备”功能，则在您的设备丢失或被盗后，能够帮助您发现并保护您的设备。您可以使用App或网站提供的位置信息在地图上定位您的设备，清除设备内容或锁定设备。我们可能直接通过您的移动设备收集位置信息；在某些情形下，我们会通过小区信号塔或Wi-Fi热点收集此信息。

提供基于位置的服务。在使用某些${name}服务时，我们或第三方服务供应商可能会使用位置信息以为您提供正确版本的服务，并且提供准确的位置，以使您获得尽可能好的用户体验，例如通过读取您的位置了解与您最近的仓库并预估送货时间，或在通过读取您的位置信息预计您距离家的位置，提醒您快回家时提前打开某些${name}设备、离家时关闭某些${name}设备等。您可以随时进入设备设置或停止使用这一应用程序来关闭这一功能。

允许您使用第三方功能。我们提供的产品中，其中有些功能是由第三方服务供应商运营的。因此，我们需要将您的信息与第三方分享才能够使您体验到完整的功能。例如，在您使用我们的服务搜索菜谱、音频、视频、食材百科、天气、路况等内容时，我们需要把您的搜索请求传给第三方服务提供商。

根据您的信息作出自动决策行为。在某些业务场景中，我们可能仅依据信息系统、算法等在内的非人工自动决策机制做出决定。如果这些决定显著影响您的合法权益，您有权要求我们做出解释，我们也将提供适当的救济方式。法律法规另有规定的除外。

收集用户反馈。您选择提供的反馈对帮助${name}改进我们的服务而言极为珍贵。为了跟踪您选择提供的反馈，${name}可能会使用您所提供的个人信息与您联系，并保留记录。

评估和分析市场、客户、产品及服务（包括询问您对我们的产品和服务的意见，以及执行客户调查），改善用户体验。我们可能会将来自某项服务的个人信息与来自其他服务的信息结合起来，用于改善服务并开发新产品和服务。我们可能使用某些选择性加入功能（例如用户体验计划）的数据，以分析用户如何使用设备和${name}服务，改善用户体验。

为您提供社区互动服务。您可以参与${name}的用户社区、与社区上的其他用户交流，您的头像、昵称等个人信息将在论坛页面展示。

发送通知。我们可能会不时使用您的个人信息来发送重要通知，例如有关购买物的物流信息和我们的条款、条件和政策变更。

管理抽奖等促销活动。如您参与抽奖、竞赛或类似推广活动，我们会将您提供的信息用于管理此类活动，如您中奖，可能会将您的手机号或昵称脱敏后以写入中奖名单并公布。若该活动是我们和第三方联合推广的，我们可能与其共享活动过程中产生的、为完成活动所必要的个人信息（参加活动的用户数、中奖名单、中奖人联系方式），以便第三方能及时向您发放奖品或为您提供服务。

直接推广。我们可能会使用您的姓名、电话号码、电子邮箱地址、${name}账号向您提供与${name}及其合作伙伴的产品和服务相关的推广资料。为了提供更好的用户体验，我们会根据您的购买历史、网站浏览历史、生日、年龄、性别和地理位置等信息推荐上述产品、服务和活动。如果您不再希望接收某些类别的电子邮件或营销短信，您可以按照提示来取消订阅。未经您另外同意，我们不会将您的个人信息传送给我们的商业伙伴用于直接推广。

提供其他${name}服务。

请注意，以上的使用方式涵盖了${name}使用个人信息的主要方式。${name}可能时不时地通过更新网站页面、APP版本或设备固件等方式，为用户提供更加多样化的服务。当我们要将信息用于本政策未载明的其他用途时，或要将基于特定目的收集而来的信息用于其他目的时，会以确认协议、具体场景下的确认行为、更新本隐私权协议等形式再次征求您的同意。

 

四、    我们如何共享信息

未经您的同意，我们将不会向${name}以外的第三方共享您的信息。但是，我们可能和下列第三方共享您的信息：

${name}智能家居关联公司。 为便于我们基于${name}平台统一帐号系统向您提供服务，推荐您可能感兴趣的信息或保护${name}或其他用户或公众的人身财产安全免遭侵害，您的个人信息可能会在${name}智能家居的关联公司之间共享。我们只会共享必要的个人信息（如为便于您通过使用【${name}帐号】来使用我们关联公司产品或服务，我们会向${name}智能家居关联公司共享您必要的账户信息），如果我们共享您的个人敏感信息或关联公司改变个人信息的使用及处理目的，将再次征求您的授权同意

授权合作伙伴。我们可能与可信的授权合作伙伴共享您的信息。我们可能会向授权合作伙伴共享您的账户信息、设备信息以及位置信息，以保障为您提供的服务顺利完成。但我们仅会出于合法、正当、必要、特定、明确的目的共享您的个人信息，并且只会共享提供服务所必要的个人信息。我们的合作伙伴无权将共享的个人信息用于任何其他用途。目前，我们的授权合作伙伴包括以下类型：

(1)    广告、分析服务类的授权合作伙伴。

(2)    供应商、服务提供商和其他合作伙伴。我们的供应商和服务提供商包括提供技术服务、电信服务、客户服务、风控服务、审计服务、法律服务的机构、金融机构、电子商务公司及平台、线下卖场等。

对我们与之共享个人信息的公司、组织和个人，我们会与其签署严格的保密协定，并会以适当的方式要求他们按照我们的说明、本隐私权政策以及其他任何相关的保密和安全措施来处理个人信息。在个人敏感数据使用上，我们要求第三方采用加密技术，从而更好地保护用户数据。一经发现其违反协议约定将会采取有效措施乃至终止合作。

遵守法律法规强制性要求、法院命令或政府主管部门要求、维护社会公共利益，或保护我们的客户、${name}或${name}员工的人身和财产安全或合法权益所合理必需的用途。

单独获得您同意的其他方。除本隐私权政策中描述的共享情况之外，我们也可能在您同意共享或提出共享请求时与第三方共享您的信息。


五、    我们如何转让信息

我们不会将您的个人信息转让给任何公司、组织和个人，但以下情况除外：

获得您的明确同意后；

在涉及合并、收购或破产清算时，如涉及到个人信息转让，我们会在要求新的持有您个人信息的公司、组织继续受此隐私权政策的约束，否则我们将要求该公司、组织重新向您征求授权同意。


六、    我们如何公开披露信息

我们仅会在以下情况下，公开披露您的个人信息：

获得您明确同意后；

基于法律的披露：基于法律法规强制性要求、法院命令或政府主管要求的情况下，我们会公开披露您的个人信息。

 

七、    一般储存期限

除非法律另有明文规定或经您允许，我们只会在本隐私权政策所述目的所需的期限内保留您的个人信息。为了保持服务的持续性和用户体验的一致性，个人信息通常不会在单项服务完成后立即删除。例如用户历史订单中的送货地址，为了使用户在下次购物时更快捷地选择送货地址，${name}会保存该地址，直至用户在自己的用户设置中选择删除。

 

八、    我们如何确保您的信息安全

我们已使用符合业界标准的安全防护措施保护您提供的个人信息，防止数据遭到未经授权访问、公开披露、使用、修改、损坏或丢失。我们会采取一切合理可行的措施，保护您的个人信息。例如，在您的浏览器与“服务”之间交换数据（如信用卡信息）时受SSL加密保护；我们同时对部分网站提供https安全浏览方式；我们会使用加密技术确保数据的保密性；对用户密码我们采用md5加盐机制散列化保存；我们会使用受信赖的保护机制防止数据遭到恶意攻击；我们会部署访问控制机制，确保只有授权人员才可访问个人信息；我们会举办安全和个人信息保护培训课程，加强员工对于保护个人信息重要性的认识。为保障您的信息安全，我们努力采取各种合理的物理、电子和管理方面的安全措施来保护您的信息，使您的信息不会被泄漏、毁损或者丢失，包括但不限于SSL、信息加密存储、数据中心的访问控制。我们对可能接触到您的信息的员工或外包人员也采取了严格管理，包括但不限于根据岗位的不同采取不同的权限控制，与他们签署保密协议，监控他们的操作情况等措施。${name}会按现有技术提供相应的安全措施来保护您的信息，提供合理的安全保障，海尔将尽力做到使您的信息不被泄漏、毁损或丢失。

我们在多种产品和服务中获得了不同的认证，例如EAL3+, ISO27001认证。

互联网环境并非百分之百安全，我们将尽力确保或担保您发送给我们的任何信息的安全性。如果我们的物理、技术、或管理防护设施遭到破坏，导致信息被非授权访问、公开披露、篡改、或毁坏，导致您的合法权益受损，我们将承担相应的法律责任。

在发生个人信息安全事件后，我们将按照法律法规的要求，及时向您告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。我们将及时将事件相关情况告知您。

您的账户均有安全保护功能，请妥善保管您的账户及密码信息。同时，为了保障您的信息安全，请在向第三方永久性售出或转让您的设备使用权之前，确保退出您的帐户并删除配对网站或APP，以确保您的设备不再（重新）匹配到您的账户。如果您售出的是设备（例如家用电器），则应重置设备并恢复出厂设置。这样，您的设备与账户之间将不再关联。如果您购买了二手的设备，请检查APP中或设备商的账户设置以确保没有未知用户和您的智能家居相关联。如果还有任何疑问，您可以选择恢复出厂设置。${name}将通过备份、对用户密码进行加密等安全措施确保您的信息不丢失，不被滥用和变造。尽管有前述安全措施，但同时也请您理解在信息网络上不存在“完美的安全措施”。

${name}不对因您未能保持个人信息的私密性而导致第三方访问您的个人信息进而造成的安全疏漏承担责任。尽管有上述规定，如果发生互联网其他任何用户未经授权使用您账户的情况或其他任何安全漏洞，您应当立即通知我们。您的协助将有助于我们保护您个人信息的私密性。

 

九、    您能做什么

访问您的个人信息

您有权访问您的个人信息。如果您想行使数据访问权，可以通过以下方式自行访问：

(1)    账户信息：如果您希望访问或编辑您的账户中的个人资料信息和支付信息、更改您的密码、添加安全信息或关闭您的账户等，您可以登录您的账户后通过访问设置页面执行此类操作。

(2)    其他个人信息：对于您在使用我们的产品或服务过程中产生的其他个人信息，只要我们不需要过多投入，我们会向您提供，请通过第十四节列明的联系方式与我们取得联系。

更正您的个人信息

当您发现我们处理的关于您的个人信息有错误时，您有权自行或要求我们做出更正。对于账户信息，您可以登录您的账户后通过访问设置页面执行此类操作。对于其他个人信息，请通过第十四节列明的联系方式与我们取得联系。

删除您的个人信息

在以下情形中，您可以向我们提出删除个人信息的请求：

(1)    如果我们处理个人信息的行为违反法律法规；

(2)    如果我们收集、使用您的个人信息，却未征得您的同意；

(3)    如果我们处理个人信息的行为违反了与您的约定；

(4)    如果您注销了账号；

(5)    如果我们不再为您提供产品和服务。

当您从我们的服务中删除信息后，我们可能不会立即备份系统中删除相应的信息，但会在备份更新时删除这些信息。

改变您授权同意的范围

您可以通过提交请求，撤销授权我们收集、使用和/或披露我们掌握或控制的您的个人信息的许可。您可以通过第十四节列明的联系方式联系我们进行相关操作。我们将会在您做出请求后的合理时间内处理您的请求，并且会根据您的请求，在此后不再收集、使用和/或披露您的个人信息。请注意，由于每个业务场景需要一些基本的个人信息才能得以完成，您撤销同意可能导致部分${name}服务不可用。

当您撤回同意后，我们将不再处理相应的个人信息。但您撤回同意的决定，不会影响此前基于您的授权而开展的个人信息处理。

注销账户

您可以通过提交请求来申请注销您的${name}账户。我们不会设置不合理的条件来阻止您注销账户，但如果注销账户将会影响您或他人的合法权益，例如您有待接收的货物、已付费而未到期的服务等，则我们会结合您对${name}服务的使用情况来判断并告知是否支持您的注销请求。

注销账户会影响您正常使用所有相关应用和服务。我们在此善意地提醒您，您注销账户的行为会给您后续使用${name}服务带来诸多不便，且注销${name}账户后，我们将停止为您提供服务，您的个人信息会保持不可被检索、访问的状态，或被匿名化处理，法律法规另有规定的除外。如您通过第三方账号快捷登录的，您需要联系该第三方处理注销账号事宜。

如果您已经深思熟虑，确定放弃账户，并符合所有注销条件，您可以通过第十四节列明的联系方式联系我们进行相关操作。${name}账户一旦被注销将不可恢复，请您在操作之前自行备份${name}账户相关的所有信息和数据。因您申请注销账户对您造成的不利影响，${name}不承担任何责任。

我们通常免费提供访问、更正、更新、删除服务，但是保留对您的数据访问请求收取合理费用的权利。

 

十、    我们如何处理未成年人的个人信息

我们的产品、网站和服务主要面向成人。对于经父母或法定监护人同意而收集未成年人个人信息的情况，我们只会在受到法律允许、父母或监护人明确同意或者保护未成年人所必要的情况下使用或公开披露合法收集到的未成年人的个人信息。

如果我们发现自己在未事先获得可证实的父母或监护人同意的情况下收集了未成年人的个人信息，则会设法尽快删除相关数据。如果家长或监护人有理由相信未成年人未经他们事先同意而向${name}提交了个人信息，请联系我们以确保删除此类个人信息，并保证未成年人取消订阅任何适用的${name}服务。

 

十一、 您的个人信息的存储地点

我们在中华人民共和国境内收集和产生的个人信息，将存储在中华人民共和国境内。

 

十二、 本隐私权政策不适用的范围

我们的隐私权政策不适用于第三方提供的产品和服务。${name}产品和服务可能包括第三方的产品和服务，以及第三方网站的链接。当您使用这些产品或服务时，也可能收集您的信息。因此，我们强烈建议您花时间阅读该第三方的隐私权政策，就像阅读我们的政策一样。我们不对第三方如何使用他们向您收集的个人信息负责，也不能控制其使用。我们的隐私权政策不适用通过我们的服务链接的其他网站。

当您使用这些特定产品时（仅为举例说明，并非穷尽式列举），适用于以下第三方条款和隐私权政策：

支付：使用支付宝、微信支付或其他第三方支付服务完成和支付订单时，您将同意第三方支付服务供应商的隐私权政策适用于您在其网站上提供的信息。

社交媒体（功能）：我们的网站包含一些社交媒体功能，例如“分享”按钮。社交媒体功能是由第三方提供的，您与这些功能的互动均受该第三方产品的隐私权政策的制约；

快捷登录：您可以使用第三方帐号（如微信、QQ、淘宝、京东等）来登录我们的网站或应用。这些服务将验证您的身份，并让您选择是否与我们分享某些个人信息（例如您的姓名和电子邮件地址），以预先填写我们的用户注册表单或绑定您已有的${name}账户。您使用的这些第三方帐号受到该等第三方相应隐私权政策的制约。

使用第三方功能：您可能通过${name}提供的应用端来使用第三方服务，例如打开、访问、浏览、检索或注册登录视频网站、音乐应用、地图应用、天气查询应用等。您在使用第三方功能时可能需要遵守第三方的服务条款和隐私权政策。

 

十三、 本隐私权政策如何更新

我们的隐私权政策可能变更。未经您明确同意，我们不会削减您按照本隐私权政策所应享有的权利。我们会在本页面上发布对本隐私权政策所做的任何变更。对于重大变更，我们还会提供更为显著的通知（包括对于某些服务，我们会通过电子邮件发送通知，说明隐私权政策的具体变更内容）。本政策所指的重大变更包括但不限于：

我们的服务模式（如处理个人信息的目的、处理的个人信息类型、个人信息的使用方式等）发生重大变化；

我们在所有权结构发生重大变化（如业务调整、破产并购等引起的所有者变更）；

个人信息公开披露的主要对象发生变化；

您参与个人信息处理方面的权利及其行使方式发生重大变化；

我们负责处理个人信息安全的责任部门、联络方式及投诉渠道发生变化时；

个人信息安全影响评估报告表明存在高风险时。


十四、 联系我们

联系方式

如果您对本隐私权政策有任何疑问、意见或建议，通过以下方式与我们处理个人信息保护事宜的部门联系：

电子邮件：${name}mall@${name}.so

邮寄地址：浙江省温州市瓯海区郭溪街道温瞿东路1286号C幢5F

响应您的请求

为保障安全，您可能需要提供书面请求，或以其他方式证明您的身份。一般情况下，我们将在30天内回复。如果您对我们的回复不满意，特别是我们的个人信息处理行为损害了您的合法权益，您还可以向网信、电信、公安及工商等监管部门进行投诉或举报。

我们可能会先要求您验证自己的身份，然后再处理您的请求。我们将在30天内做出答复。如您不满意，还可以通过本节列明的联系方式联系我们进行投诉。

对于您合理的请求，我们原则上不收取费用，但对多次重复、超出合理限度的请求，我们将酌情收取一定的成本费用。对于那些无端重复、需要过多技术手段（例如，需要开发新系统或从根本上改变现行惯例）、给他人合法权益带来风险或者非常不切实际（例如，涉及备份磁带上存放的信息）的请求，我们可能会予以拒绝。

在以下情形中，按照法律法规要求，我们将无法响应您的请求：

(1)    与国家安全、国防安全有关的，或者违反政府部门的强制性要求；

(2)    与公共安全、公共卫生、重大公共利益有关的；

(3)    与犯罪侦查、起诉和审判等有关的；

(4)    有充分证据表明您存在主观恶意或滥用权利的；

(5)    响应您的请求将导致您或其他个人、组织的合法权益受到严重损害的。

(6)    涉及商业秘密的。

 

提示：基于各国和不同司法管辖区法律和监管要求等方面的差别，如您在其他司法管辖区使用我们的服务时发现当地语言版本的隐私权政策，与本隐私权政策有所不同，请以您看到的当地语言版本为准。如有与本隐私权政策或您的个人信息使用相关的事项，请联系您所在地点特定的法律声明页面所载列的联系方式。

©${company}     版权所有，保留一切权利`)
export const protocol = wordWrap(`前言
《${name}用户注册协议》是由${company}与您（下称“用户”或“您”，是指通过${name}注册使用服务的自然人、法人或其他组织）在使用${company}出品的互联网产品${name}安全服务之前，就注册${name}安全服务账号时签署的针对双方权利、义务进行合理约定电子协议。您知悉本协议具有法律效力，当您勾选“我同意”或点击“我已阅读并遵守该协议”按钮后，即表示您已经仔细阅读、充分了解并完全的、毫无保留的接受本协议的所有条款。

一、重要须知：
1、您应认真阅读（未成年人应当在监护人陪同下阅读）、充分理解本《${name}用户注册协议》中各条款，包括免除或者限制${name}责任的免责条款及对用户的权利限制条款。
2、除非您接受本协议，否则用户无权也无必要继续接受${name}的服务，可以退出本次注册。用户点击同意并继续使用${name}的服务，视为用户已完全地接受本协议。
3、本协议意境签署，具有法律效力，请您谨慎考虑是否接受本协议。
4、在您签署本协议之后，此文本可能因国家、产品以及履行本协议的环境发生变化而进行修改，修改后的协议发布在本网站上，若您对修改后的协议有异议的，请立即停止登录、使用${name}产品及服务，若您登录或继续使用${name}产品，视为对修改后的协议予以认可。

二、关于“${name}账号”
1、用户在接受本协议之后，有权选择未被其他用户使用过的字母、文字及符号组合作为用户的${name}账号，并自行设置符合要求的密码。用户设置的账号、密码是用户用以登录${name}产品，接受${name}服务，持有相关虚拟产品的凭证。
2、${name}账号性质上是${name}提供服务授予用户的身份识别凭证，${name}账号是${name}相应计算机软件作品的一部分，即${name}将相关产品计算机软件著作权授权给注册用户的授权凭证。
3、${name}账号还是用户持有、使用相关虚拟产品的身份凭证。用户若需要接受${name}提供的增值服务，${name}账号同时也是用户支付费用、接受增值服务的凭证。
4、用户一经注册${name}账号，除非子频道要求单独开通权限，用户有权利用该账号使用${name}各个频道的单项服务，当用户使用${name}各单项服务时，用户的使用行为视为其对该单项服务的服务条款以及${name}在该单项服务中发出的各类公告的同意。
5、${name}账号仅限于在${name}网站/app/小程序/微信公众号上注册用户本人使用，禁止赠与、借用、租用、转让或卖售。如果${name}发现使用者并非账号初始注册人，有权在未经通知的情况下回收该账号而无需向该账号使用人承担法律责任，由此带来的包括并不限于用户通讯中断、用户资料和道具等清空等损失由用户自行承担。
6、用户应当妥善保管自己的${name}账号、密码，用户就其账号及密码项下之一切活动负全部责任，包括用户数据的修改，虚拟道具的损失以及其他所有的损失由用户自行承担。用户须重视${name}账号密码保护。用户如发现他人未经许可使用其账号时应立即通知${name}服务中心。
7、用户${name}账号在丢失或遗忘密码后，可遵照${name}的申诉途径及时申诉请求找回账号。用户应不断提供能增加账号安全性的个人密码保护资料。用户可以凭初始注册资料及个人密码保护资料填写申诉单向${name}申请找回账号，${name}的密码找回机制仅负责识别申诉单上所填资料与系统记录资料的正确性，而无法识别申诉人是否系真正账号权使用人。对用户因被他人冒名申诉而致的任何损失，${name}不承担任何责任，用户知晓${name}账号及密码保管责任在于用户，${name}不承担${name}账号丢失或遗忘密码后用户一定能通过申诉找回账号。
8、用户保证注册${name}账号时填写的身份信息及个人资料是真实的，不得以虚假、冒用的居民身份信息、企业注册信息、组织机构代码信息进行注册或认证，任何非法、不真实、不准确的用户信息所产生的责任由用户承担。用户应不断更新注册资料，符合及时、详尽、真实、准确的要求。所有原始键入的资料将引用用户的账号注册资料。如果因用户的注册信息或认证信息不真实而引起的问题，以及对问题发生所带来的后果，${name}不负任何责任。
9、${name}账号的所有权属于${name}。${name}有权根据运营需要。发布${name}平台规则规范。
10、如用户违反法律法规、侵犯第三方合法权益（包括但不限于知识产权）、违反${name}各服务协议或平台规则等规定，${name}有权根据相关规则进行违规判定，并采取相应限制或处罚措施，包括但不限于：限制或冻结用户对${name}账号的使用、限制或停止某项单独服务（如账号服务及直播服务），终止或纠正用户的侵权行为，扣除保证金，扣减虚拟产品或相关服务费用并根据实际情况决定是否恢复使用。
11、为了充分利用${name}账号资源，若用户存在长期未登录使用${name}账号的情形，${name}有权对该账号进行回收、替换等终止使用操作。
12、${name}依照平台规则限制、冻结、回收、替换或终止用户对${name}账号的使用，可能会给用户造成一定的损失，该损失由用户自行承担，${name}不承担任何责任。

三、用户不得从事以下行为：
1、利用${name}服务产品发表、传送、传播、储存危害国家安全、国家统一、社会稳定的内容，或侮辱诽谤、色情、暴力、引起他人不安及任何违反国家法律法规政策的内容或者设置含有上述内容的网名、角色名。
2、利用${name}服务发表、传送、传播、储存侵害他人知识产权、商业机密权、肖像权、隐私权等合法权利的内容。
3、进行任何危害计算机网络安全的行为，包括但不限于：使用未经许可的数据或进入未经许可的服务器/账户；未经允许进入公众计算机网络或或者使用他人计算机系统并删除、修改、增加存储信息；未经许可，企图探查、扫描、测试本“软件”系统或网络的弱点或其他实施破坏网络安全的行为；企图干涉、破坏本“软件”系统或网站的正常运行，故意传播恶意程序或病毒以及其他破坏干扰正常网络信息服务行为；伪造TCP/IP数据包名称或部分名称。
4、进行任何破坏${name}服务公平性或者其他影响应用正常秩序的行为，如主动或被动刷分，合伙作弊、使用外挂或者其他的作弊软件、利用BUG（又称“漏洞”或者“缺陷”）来获得不正当的非法利益，或者利用互联网或其他方式将外挂、作弊软件、BUG公之于众。
5、进行任何注入发布广告、销售商品的商业行为，或者进行任何非法的侵害${name}利益的行为，如贩卖${name}平台虚拟货币、外挂、道具等。
6、进行其他任何违法以及侵犯其他个人、公司、社会团体、组织的合法权益的行为。
7、除以上条款外，用户使用${name}平台，还应当遵守《用户管理条例》（见附录一）。

四、${name}声明：
用户须明白，在使用${name}服务可能存在有来自任何他人的包括威胁性的、诽谤的、令人反感的或非法的内容或行为或对他人权利的侵犯（包括知识产权）的匿名或冒名的信息的风险，用户须承担以上风险，${name}对服务不作担保，不论是明确的或隐含的，包括所有有关信息真实性、适当性、适于某一特定用途、所有权和非侵权性的默示担保和条件，对因此导致任何因用户不正当或非法使用服务产生的直接、间接、偶然、特殊及后续的损害，不承担任何责任。
2、使用${name}服务必须遵守国家有关法律和政策等，维护国家利益，保护国家安全，并遵守本条款，对于用户违法或违反本协议的使用（包括但不限于言论发表、传送等）而引起的一切责任，由用户负全部责任。
3、${name}服务同大多数因特网产品一样，易受到各种安全问题的困扰，包括但不限于：
1）透露详细个人资料，被不法分子利用，造成现实生活中的骚扰；
2）哄骗、破译密码；
3）下载安装的其他软件中含有“特洛伊木马”等病毒，威胁到个人计算机上信息和数据的安全，继而威胁到本服务的使用。对于发生上述情况的，用户应该自行承担责任。
4、用户须明白，${name}为了服务整体运营的需求，有权在公告通知后修改或中断、中止或终止服务而不需通知您的权利，而无须向第三方负责或承担任何赔偿责任。
5、用户理解，互联网技术的不稳定性，可能导致政府政策管制、病毒入侵、黑客攻击、服务器系统奔溃或者其他现今技术无法解决的风险发生可能导致${name}服务中断或账号道具损失，用户对此非人为因为引起的损失由用户承担责任。

五、知识产权
1、${name}的服务包括${name}运营的网站、网页应用、软件以及内涵的文字、图片、视频、音频等元素，${name}服务标志、标识以及专利权，${name}对此享有上述知识产权。
2、用户不得以${name}服务涉及的相关网页、应用、软件等产品进行反向工程、反向汇编、反向编译等。
3、用户使用${name}服务只能在《用户协议》以及相应的授权许可协议授权的范围使用${name}知识产权，未经授权超范围使用的构成对${name}的侵权。
4、用户在使用${name}服务时发表上传的文字、图片、视频、软件以及表演等用户原创的信息，此部分信息的知识产权归用户，但用户的发表、上传行为是对${name}服务平台的授权，用户确认其发表、上传的信息非独占性、永久性的授权，该授权可转授权。${name}可将前述信息在${name}旗下的服务平台上使用，可再次编辑后使用，也可以由${name}授权给合作方使用。

六、用户个人隐私保护：
1、${name}非常重视用户的个人信息保护，不得向任何第三方公开、透露您的个人信息。同时，请用户注意勿在使用${name}服务中透露自己的各类财产账户、银行卡、信用卡、第三方支付账户及对应密码等重要资料，否则由此带来的任何损失由用户自行承担。
2、用户的${name}账号、密码属于保密信息，${name}应当采取积极的措施保护用户账号、密码的安全。
3、用户的注册信息作为${name}的商业秘密进来保护。但用户同时明白，互联网的开放性以及技术更新非常快，非${name}可控制的因素导致用户泄露的， ${name}不承担责任。
4、用户在使用${name}服务时不可将自认为隐私的信息发表、上传至${name}，也不可将该信息通过${name}的服务传播给其他人，若用户的行为引起的隐私泄露，由用户承担责任。
5、${name}在提供服务时可能会搜集用户信息，${name}会明确告知用户，通常信息仅限于用户姓名、性别、年龄、出生日期、身份证号、家庭住址、教育程度、公司情况、所属行业、兴趣爱好等。
6、就下列相关事宜的发生，${name}不承担任何法律责任：
1）${name}根据法律规定或相关政府、司法机关的要求提供您的个人信息；
2）由于用户将用户密码告知他人或与他人共享注册账户，由此导致的个人信息的泄露，或其他非因${name}原因到安置的个人信息的泄露；
3）任何由于黑客攻击、电脑病毒侵入造成的信息泄露；
4）因不可抗力导致的信息泄露；

七、其他条款：
1、本协议的签署地点为浙江温州，若用户与${name}因履行本协议发生争议的，双方均应本着友好协商的原则加以解决。协商解决未果，任何一方均可以提请浙江省温州市瓯海区有管辖权的人民法院诉讼解决。
2、本协议由${name}公布在网站上，对${name}具有法律约束力；用户一经点击接受或者直接登录的行为视为对本协议的接受，对用户具有法律约束力。
3、${name}旗下具体的网站、网页引用、软件的使用由用户和${name}的业务平台另行签署相关软件授权及服务协议。
4、协议附件是本协议的组成部分，与本协议条款具同等法律效力，本协议正文另有约定的除外。
5、在法律许可范围内享有本用户协议的最终解释权。

附录一《2020年度用户管理条例》
用户在直播间观看直播时，应遵守国家有关法律，网络礼仪，遵守直播的规定，使用恰当的语言文字聊天。不得违反以下规定：
一、任何人不得在聊天室中发布含有下列内容之一的文字、图片、视频信息：
（1）、不得传播违反宪法所确定的基本原则的信息；
（2）、不得传播危害国家安全、泄露国家秘密、损害国家荣誉和利益、颠覆国家政权、破坏国家统一的信息；
（3）、不得传播散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的信息；
（4）、不得传播扰乱社会秩序、破坏社会稳定的谣言、言论；
（5）、不得传播宣扬种族歧视、煽动民族仇恨、破坏民族团结的言论；
（6）、不得传播破坏国家宗教政策，宣扬邪教和封建迷信的言论；
（7）、不得发表漫骂、辱骂、对他人进行人身攻击、人格侮辱、侵害他人隐私、侵害他人合法权益的言论；
（8）、不得传播含有法律禁止的其他内容的言论；
（9）、不得违反《网络表演经营活动管理办法》相关规定；
二、用户及主播在直播间禁止使用一下内容作为昵称或用户名：
（1）、不得使用与国家领导人相同或有损国家和国家领导人以及著名人物形象的昵称或用户名（包括国家现任领导人及历届国家领导人），禁止使用战争或政治要犯名字相同、相似或谐音的昵称；
（2）、禁止使用带有商业广告、网页广告成分的昵称；
（3）、不得使用违反社会公共道德，带有人身侵犯、攻击、污秽、敌对、性别歧视、个人侮辱、自我侮辱、猥亵或不雅观的昵称；
（4）、不得故意使用甚至恶意雷同仿冒管理员、主持人或其他聊友的ID和昵称，禁止使用类似聊天系统用词的昵称（系统、注意、警告、公告等）；
（5）、禁止使用违反第五条管理规定的八项条款内容的名字做昵称；
三、未经公司许可，禁止用户在直播间发放商业广告或从事类似商业性质的广告行为。
四、不得利用可能存在的软件和机器漏洞，在直播间攻击他人或发送大量垃圾信息，影响直播及聊天秩序。不得在直播间内传播攻击、控制他人机器的信息或链接。
五、不得在直播间三次以上重复发表相同或类似相同的聊天信息或漫无目的的滥用表情进行刷屏，影响其他用户正常聊天。
六、在直播间举行活动期间，违反活动规定，不遵守相关公告提醒对活动秩序构成干扰的聊友，管理员有权按照规定进行相关处理以维持房间秩序。
七、严禁在直播间进行帮派活动或有组织的干扰房间正常秩序。
八、禁止盗用他人账号进入直播间。
九、处理规定
对于违反以上规定的聊友，运营管理人员有权对违规者视其违规情节轻重，进行口头警告、封闭发言、请出直播间、封闭账号等处理。
`)